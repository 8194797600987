import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
            button: {
                home: "Home",
                resume: 'Résumé',
                resumeCaps: "RÉSUMÉ",
                photography: 'Photography',
                about: "About Me",
                projects: "Projects",
                viewHome: "View on Home Page",
                github: "View GitHub portfolio",
                sourcecode: "Source Code",
                video: "Video Demo",
                iosdownload: "iOS Download",
            },
            aboutParagraph: {
              p1: "I'm a final year Software Engineering student at McGill University, currently working as a Fullstack developer at LispLogics. I have over 3 years of industry experience with fullstack development, having previously worked as a software developer intern at Microsoft/Nuance, Hivestack, ResMed, and Nokia.",
              p2: "I have 3 years of experience with fullstack development, including frontend experience with libraries/frameworks such as Vue, Angular, and React, as well as backend experience using Java/Spring, Python/Flask, and TypeScript/Express.js",
              p3: "In my free time, I enjoy Photography and Calisthenics!"
            },
            projects: {
              listmaker: {
                h: "A React Native mobile app that simplifies the process of creating grocery lists.",
                p0: "Grocery items are arranged as cards which can be swiped left (discard) or right (add to list)",
                p1: "The app offers a 'quick-add' menu for you to easily scroll through and search a list of products",
                p2: "The list can be exported as a PDF, printed, e-mailed, or viewed within the app while shopping",
                p3: "All product data is stored as a JSON file in an Amazon S3 bucket. Quickly add missing products using the built-in barcode scanner",
              },
              thousandpushups: {
                h: "A React Native app that offers a fun way to keep track of push-ups (using your nose!)",
                p0: "Place your device directly below your face, while in the push-up position",
                p1: "Perform a push-up, aiming your nose at the circular counter (this also forces reps to be more slow and controlled)",
                p2: "Set custom rep targets and time-limits (which is displayed live as you count push-ups)",
                p3: "The timer can be paused at any time. This will reveal an overlay that allows you to wipe off sweat without accidentally pressing any buttons",
                p4: "The app has multiple programs for different push-up variations"
              },
              carcatalog: {
                h: "A JavaFX application that simplifies the process of researching vehicles online. The main menu includes a sorted table of vehicle names. After selecting a car, you can use a variety of tools to help refine your search (for example, the colour palette and price range slider). This data is used to query a variety of REST APIs to retrieve as much information as possible on the chosen car.",
                p0: "Once the search is complete, navigate between the tabs to view images of the car, reviews, videos, and listings on eBay/Kijiji"
              },
              liveequationeditor: {
                h: "A site for uploading handwritten equation images, automatically viewing the formatted equations, and evaluating them on WolframAlpha/Symbolab",
              },
              splendor: {
                h: "A full-stack online multiplayer implementation of the Splendor board game",
              },
              prompttotutorial: {
                p0: "AI-powered video generation - Creates informational videos based on user prompts (with narrations & visuals)",
                p1: "Leverages OpenAI's GPT-4 to generate video scripts based on any provided topic",
                p2: "Uses Bing Image Search APIs + Azure Text to Speech to generate the visuals and narrations",
                p3: "Uses React for the UI, and the Remotion framework to render and export the final videos",
              },
            },
            contact: "Contact Information",
            roles: {
              softwareDeveloperIntern: "Software Developer Intern",
              associateSoftwareEngineer: "Associate Software Engineer",
              fullstackDeveloper: "Fullstack Developer",
              intern: "Student Intern",
              stocker: "Stocker",
            },
            resume: {
              h: "I'm a full-stack developer with over 3 years of industry experience (full-time + 9 internship terms). I'm currently working as a full-stack + mobile developer at LispLogics, while completing my degree as a McGill Software Engineering student. I've previously worked as a software developer intern at Microsoft, Nuance, Hivestack, Nokia and ResMed.",
              phone: "Phone",
              education: "Education",
              employment: "Employment",
              skills: "Skills",
              skillsList: {
                languages: "LANGUAGES",
                libraries: "LIBRARIES/FRAMEWORKS",
                tools: "TOOLS",
              },
              volunteering: "Volunteering",
              personal: "Personal",
              dates: {
                may: "May",
                august: "Aug",
                january: "Jan",
                april: "Apr",
                september: "Sept",
                november: "Nov",
                december: "Dec",
                june: "June",
                july: "July",
                current: "Current",
              },
              lisplogics: {
                p1: "Leading the development of the LispLogics web platform, a real-time full stack application which facilitates the management of critical operations for ride-sharing companies (including BIXI and Communauto)",
                p2: "Designing & implementing a mobile dispatch platform (Golang/gRPC backend + React Native mobile application) which notifies truck drivers when tasks are dispatched to them through the web platform. The app allows drivers to update dispatchers on the progress of their tasks in real-time.",
                p3: "Helping to secure + onboard new clients (bike/car share systems, etc.) by creating & demoing proof-of-concept integrations of the platform for different cities and meeting with & assisting dispatchers/truck drivers with field testing",
              },
              lisplogics2: {
                p1: "Created a React.js frontend (designed using Figma) which provides a map & control menu for managing the dispatch of bike relocation employees. The UI displays the live location of truck drivers (using Mapbox + Samsara), as well as the current inventory & capacity of stations throughout the city of Montreal. It also shows the driver's tasks and the shortest route to complete the tasks from their live location.",
                p2: "Developed an Express.js + Typescript backend which pushes live updates from a Redis database to the frontend through web-socket connections (using Socket.io).",
              },
              microsoft: {
                p1: "Assisted with the integration of Azure OpenAI & GPT-4 into Nuance Atlas (an AI Copilot tool which assists call agents with fraud protection, live transcription/translation, caller sentiment analysis, etc). Created a ChatGPT component for the Atlas UI, which allows call agents to develop/send prompts and receive responses while on calls with customers (using transcriptions from their conversation as context)",
                p2: "Designed & developed a feature for one of Nuance's client projects which retrieves a list of pre-defined agent scripts from a SQL Server database (via Hibernate) to be displayed and automatically detected during calls with customers. Implemented both the backend (Java/Spring), and UI (Angular) changes based on Figma designs",
              },
              nuance: {
                p1: "Developed multiple features for Nuance's Agent AI/Copilot tool (Atlas), which assists call agents by providing fraud protection, live transcription/translation, caller sentiment analysis, etc.",
                p2: "Worked on designing/implementing a feature to summarize Agent/Customer conversation transcriptions using Microsoft’s Azure Cognitive Services",
                p3: "Added a feature to manage call recording based on the customer's consent",
              },
              hivestack: {
                p1: "Contributed multiple features and bugfixes (including high-priority hotfixes) to the platform's UI (Vue.js) and backend (Python/AIOHTTP)",
                p2: "Planned and developed an Advertiser RFP feature, which allows Media Owners to generate a 'Request for Proposal' document (in PDF format), containing targeting parameters (audience demographics, locations, markets, etc.) and estimated cost + impression statistics for upcoming advertising campaigns",
                p3: "Performed MySQL database migrations using SQLAlchemy and Alembic",
              },
              resmed: {
                p1: "Created a web application with React/MaterialUI for ResMed's Care Check-In product that lets developers easily create and manage response data from patient surveys.",
                p2: "The app scans 200,000+ records from an AWS DynamoDB table and loads them into a virtualized list. It allows users to create custom survey responses through a form, which are then converted to a JSON object and either published directly to DynamoDB, or pushed to an SQS queue to trigger a Lambda function that processes the data further. Records can also be filtered, edited, and deleted from DynamoDB.",
                p3: "Gained experience with using Terraform and AWS CodePipeline to configure and manage the building/deployment of the app",
                p4: "Additionally made improvements to the REST APIs for ResMed's SMS notification service",
              },
              nokia1: {
                p1: "Worked as a part of a Scrum team for Nokia's WaveSuite product",
                p2: "Resolved bugs and developed various features for WaveSuite's UI, REST APIs, and Path Computation Neo4j plugin",
                p3: "Used tools such as Ansible and Docker to fix bugs related to WaveSuite's installation process",
                p4: "Gained experience with using Git and Bitbucket, as well as JIRA and Confluence",
              },
              nokia2: {
                p1: "Managed the SharePoint site for Nokia Global Network Engineering (GNE):",
                p2: "Developed a successful workflow solution that identified missing metadata in document uploads, and automatically e-mailed the document editor.",
                p3: "Also linked the Microsoft SharePoint group for GNE members with Nokia's Active Directory employee database in order to automatically keep site permissions updated.",
              },
              staples: {
                p1: "Stocked shelves with merchandise and assisted customers with finding products during the back-to-school shopping period.",
              },
              fastfoodhaven: {
                winner: "1st Place",
                p1: "Worked with a team of 4 people to develop one of the winning projects for cuHacking 2020 (Carleton University)",
                p2: "Used UIPath's web scraping capability as well as JavaScript to automatically retrieve coupons from various locations and display them in a single, convenient website",
              },
              loadboard: {
                p1: "A mobile app created as an entry for McGill's CodeJam hackathon",
                p2: "The app allows truck drivers to efficiently schedule their loading/unloading time according to their arrival time. It also displays information related to loading docks, including the calculated wait times for each expected carrier",
                p3: "Used Java/Spring for the backend APIs and React Native for the mobile UI"
              },
              solidguard: {
                p1: "Full-stack Blockchain security application used to take quick action against newly-discovered vulnerabilities in the blockchain",
                p2: "Worked with a team of students over a series of sprints to develop the application (mainly focused on developing the frontend)",
              },
              splendor: {
                p1: "Completed a year-long group project course that simulates the software development process (requirements elicitation, implementation, integration, testing, etc.)",
                p2: "Developed multiple wireframes using Figma to outline the UI design, and implemented the frontend using HTML web components + VanillaJS",
                p3: "Implemented a Java/Spring Boot backend, including long-polling endpoints for pushing state updates to the client. The backend also integrates a Lobby Service to provide additional lobby-related functionality (authentication/authorization via OAuth, creating/joining game sessions and saving game data)",
                p4: "Added Dockerfiles to deploy the backend, and ensured best practices by setting up GitHub Actions and enforcing the use of checkstyle/unit tests",
              },
              mchacks: {
                t: "Mentor + Judge",
                n: "McHacks 2023 (Hackathon)",
                p1: "Assisted hackathon participants with the implementation of their projects and answered technical questions",
                p2: "Helped with judging the final projects/presentations",
              },
              ottawa: {
                t: "Ottawa Art Gallery",
                n: "Ottawa International Animation Festival",
                p1: "Assisted with setup of the venue, checked passes, and recorded attendance of screenings",
                p2: "Validated IDs and helped to serve beverages",
              },
              techfair: "Assisted with the coat-checking service, welcoming employers, and setting up the venue",
              ferrari: {
                t: "FCA Ferrari Day Festival",
                p1: "Helped to manage the annual Ottawa Ferrari Festival along with a team of student volunteers",
              },
              philippines: {
                t: "Videographer - Philippines Independence Day Celebration",
                h: "Vincent Massey Park",
                p1: "Filmed the Philippines Independence Day Entertainment Show",
                p2: "Synchronized the recorded footage with audio recordings, and spent a total of 20 hours editing the final product",
              },
            },
            photography: {
              t: "PHOTO GALLERY",
              h1: "I've been using the Canon EOS Rebel T6i for 5+ years to take my photos, most of which were shot in Ontario/Québec (Canada), and Kerala (India).",
              h2: "Hover over an image to view where it was taken (or download it).",
              h3: "Visit my ",
              h4: " to view more photos",
            },
        }
      },
      fr: {
        translation: {
            button: {
                home: "Accueil",
                resume: 'CV',
                resumeCaps: "CV",
                photography: "Photographie",
                about: "À Propos de Moi",
                projects: "Projets",
                viewHome: "Afficher sur la page d'accueil",
                github: "GitHub",
                sourcecode: "Code Source",
                video: "Démo Vidéo",
                iosdownload: "Télécharger sur iOS",
            },
            aboutParagraph: {
              p1: "Je suis un étudiant de 4e année en génie logiciel à l'Université McGill. J'ai plus de 2.5 ans d'expérience professionnelle, ayant précédemment travaillé comme développeur de logiciels stagiaire chez Microsoft/Nuance, Hivestack, Nokia et ResMed. J'ai un ensemble diversifié de compétences allant de la programmation à la conception.",
              p2: "Mon langage de programmation principal est Java. J'ai également de l'expérience avec Python et JavaScript, y compris des bibliothèques/frameworks frontaux tels que Vue, Angular et React.",
              p3: "Dans mon temps libre, j'aime la photographie et la callisthénie !"
            },
            projects: {
              listmaker: {
                h: "Une application mobile React Native qui simplifie le processus de création de listes de courses.",
                p0: "Les produits d'épicerie sont disposés sous forme de cartes qui peuvent être glissées vers la gauche (jeter) ou vers la droite (ajouter à la liste)",
                p1: "L'application propose un menu 'ajout rapide' pour vous permettre de faire défiler et de rechercher facilement une liste de produits",
                p2: "La liste peut être exportée au format PDF, imprimée, envoyée par e-mail ou consultée dans l'application lors de vos courses",
                p3: "Toutes les données produit se trouvent dans un fichier JSON, dans un compartiment Amazon S3. Ajoutez rapidement les produits manquants à l'aide du lecteur de code-barres intégré",
              },
              thousandpushups: {
                h: "Une application React Native qui offre une façon amusante de suivre les pompes (vous utilisez votre nez !)",
                p0: "Placez votre téléphone directement sous votre visage, en position push-up",
                p1: "Effectuez une pompe en pointant votre nez vers le compteur circulaire (cela oblige également les répétitions à être plus lentes et contrôlées)",
                p2: "Définissez des objectifs et des délais de répétition personnalisés (qui s'affichent en direct lorsque vous comptez les répétitions)",
                p3: "La minuterie peut être mise en pause à tout moment. Cela révélera une superposition qui vous permettra d'essuyer la transpiration sans appuyer accidentellement sur les boutons",
                p4: "L'application dispose de plusieurs programmes pour différentes variantes de pompes"
              },
              carcatalog: {
                h: "Une application JavaFX qui simplifie le processus de recherche de véhicules en ligne. Le menu principal comprend un tableau trié des noms de véhicules. Après avoir sélectionné une voiture, vous pouvez utiliser une variété d'outils pour affiner votre recherche (par exemple, la palette de couleurs et le curseur de gamme de prix). Ces données sont utilisées pour interroger une variété d'API REST afin de récupérer autant d'informations que possible sur la voiture choisie.",
                p0: "Lorsque la recherche est terminée, naviguez entre les onglets pour afficher des images de la voiture, des critiques, des vidéos et des annonces sur eBay/Kijiji"
              },
              liveequationeditor: {
                h: "Un site pour télécharger des images d'équations manuscrites, visualiser automatiquement les équations formatées et les évaluer sur WolframAlpha/Symbolab",
              },
              splendor: {
                h: "Une implémentation multijoueur en ligne du jeu de société Splendor",
              },
              prompttotutorial: {
                p0: "Génération de vidéos via l'IA - Crée des vidéos d'information (avec voix et visuels)",
                p1: "Utilise le GPT-4 d'OpenAI pour générer des scripts vidéo basés sur n'importe quel sujet fourni",
                p2: "Utilise les API Bing Image Search + Azure Text to Speech pour générer les visuels et les narrations",
                p3: "Utilise React pour l'interface utilisateur et Remotion pour rendre et exporter les vidéos finales",
              },
            },
            contact: "Informations de Contact",
            roles: {
              softwareDeveloperIntern: "Développeur Logiciel (Stagiaire)",
              associateSoftwareEngineer: "Développeur Logiciel (Stagiaire)",
              fullstackDeveloper: "Développeur Fullstack",
              intern: "Stagiaire",
              stocker: "Stockeur",
            },
            resume: {
              h: "Je suis un étudiant en génie logiciel à l'Université McGill avec plus de 2.5 ans (7 stages) d'expérience de travail dans un environnement Agile/Scrum. J'ai été développeur logiciel stagiaire chez Microsoft/Nuance, Hivestack, Nokia et ResMed. J'ai également de l'expérience dans la création/l'hébergement de sites Web statiques avec ReactJS et AWS, le développement avec des API REST et la création d'applications de bureau/mobiles avec React Native et JavaFX.",
              phone: "Téléphone",
              education: "Formation",
              employment: "Expérience Professionnelle",
              skills: "Compétences",
              skillsList: {
                languages: "LANGAGES DE PROGRAMMATION",
                libraries: "BIBLIOTHEQUES/FRAMEWORKS",
                tools: "OUTILS",
              },
              volunteering: "Expérience de Bénévolat",
              personal: "Projets Personnels",
              dates: {
                may: "Mai",
                august: "Août",
                january: "Janvier",
                april: "Avril",
                september: "Septembre",
                november: "Novembre",
                june: "Juin",
                july: "Juillet",
                current: "Actuellement",
              },
              lisplogics: {
                p1: "Leading the development of a Full-stack application which automates the dispatch/scheduling of bike/car relocation for ride-sharing companies (including Bixi and Communauto) through the use of machinDéveloppement d'une application Full-stack qui automatise la répartition/planification des déplacements vélos/voitures pour les entreprises de mobilité partagée (Bixi et Communauto inclus) grâce à l'utilisation d'algorithmes d'IA",
                p2: "Création d'une interface utilisateur React.js (basée sur les conceptions Figma) contenant une carte intuitive et un panneau de contrôle pour gérer la répartition des employés de relocalisation de vélos et des stations/quais de vélo dans toute la ville de Montréal. Il comprend des données en direct sur l'emplacement et les tâches/itinéraires des employés, ainsi que l'inventaire actuel et la capacité de chaque station.",
                p3: "Développement d'un backend Express.js + Typescript qui envoie des mises à jour en direct au frontend via des connexions web-socket (via Socket.io).",
                p4: "Améliorer continuellement l'application avec les commentaires des clients et effectuer des déploiements réguliers pour que les clients utilisent l'application avec les données de production",
              },
              nuance: {
                p1: "Développement de plusieurs fonctionnalités pour l'outil Agent AI/Copilot (Atlas) de Nuance, qui aide les agents d'appel en fournissant une protection contre la fraude, une transcription/traduction en direct, une analyse des sentiments de l'appelant, etc.",
                p2: "Aide à l'intégration d'Azure OpenAI et GPT-4 à Atlas. Création d'un composant de chat pour l'interface utilisateur Atlas, qui permet aux agents d'appel de développer/envoyer des invites et de recevoir des réponses lors d'appels avec des clients (en utilisant des transcriptions de leur conversation pour le contexte)",
                p3: "Conception et développement d'une fonctionnalité Atlas pour l'un des clients de Nuance qui récupère une liste de scripts d'agents prédéfinis à partir d'une base de données SQL Server (via Hibernate) pour être affichés et automatiquement détectés lors des appels avec les clients. Mise en œuvre des modifications du backend (Java/Spring) et de l'interface utilisateur (Angular) basées sur les conceptions Figma",
                p4: "Travail sur la mise en œuvre d'une fonctionnalité pour résumer les transcriptions de conversation agent/client à l'aide des API Azure Cognitive Services de Microsoft",
              },
              hivestack: {
                p1: "Contribution à plusieurs fonctionnalités et corrections de bogues (y compris des correctifs hautement prioritaires) à l'interface utilisateur (Vue.js) et au backend (Python/AIOHTTP)",
                p2: "Planification et développement d'une fonction 'Advertiser RFP', qui permet aux propriétaires de médias de générer un document 'Demande de proposition' (au format PDF), contenant des paramètres de ciblage (démographie de l'audience, emplacements, marchés, etc.) et des statistiques estimées de coût + impressions pour campagnes publicitaires à venir",
                p3: "Migrations de bases de données MySQL avec SQLAlchemy et Alembic",
              },
              resmed: {
                p1: "Création d'une application Web avec React/MaterialUI pour le produit Care Check-In de ResMed qui permet aux développeurs de créer et de gérer facilement les données de réponse à partir d'enquêtes envoyées aux patients.",
                p2: "L'application analyse plus de 200 000 enregistrements à partir d'une table AWS DynamoDB et les charge dans une liste virtualisée. Il permet aux utilisateurs de créer des réponses d'enquête personnalisées via un formulaire, qui sont ensuite converties en objet JSON et soit publiées directement sur DynamoDB, soit poussées vers une file d'attente SQS pour déclencher une fonction Lambda qui traite davantage les données. Les enregistrements peuvent également être filtrés, modifiés et supprimés de DynamoDB.",
                p3: "Acquérir de l'expérience avec l'utilisation de Terraform et d'AWS CodePipeline pour configurer et gérer la création/le déploiement de l'application",
                p4: "Améliorations apportées aux API REST pour le service de notification par SMS de ResMed",
              },
              nokia1: {
                p1: "A travaillé au sein d'une équipe Scrum pour le produit WaveSuite de Nokia",
                p2: "Développement de diverses fonctionnalités et corrections de bogues pour l'interface utilisateur et les API REST de WaveSuite",
                p3: "Utilisation d'outils tels qu'Ansible et Docker pour corriger les bogues liés au processus d'installation de WaveSuite",
                p4: "Acquérir de l'expérience avec l'utilisation de Git et Bitbucket, ainsi que JIRA et Confluence",
              },
              nokia2: {
                p1: "Gestion du site SharePoint pour Nokia Global Network Engineering (GNE) :",
                p2: "Développement d'une solution efficace qui a identifié les métadonnées manquantes dans les téléchargements de documents et a automatiquement envoyé un e-mail à l'éditeur de documents.",
                p3: "Liaison du groupe Microsoft SharePoint pour les membres GNE avec la base de données des employés Active Directory de Nokia afin de maintenir automatiquement à jour les autorisations du site.",
              },
              staples: {
                p1: "Remplir les étagères de marchandises et aider les clients à trouver des produits pendant la période de magasinage de la rentrée.",
              },
              fastfoodhaven: {
                winner: "Gagnant de la 1ère place",
                p1: "J'ai travaillé avec une équipe de 4 personnes pour développer un des projets gagnants pour cuHacking 2020 (Université Carleton)",
                p2: "Utilisation d'UIPath ainsi que de JavaScript pour récupérer automatiquement des coupons de différents emplacements et les afficher sur un site Web unique et pratique",
              },
              loadboard: {
                p1: "Une application mobile créée comme entrée pour le hackathon CodeJam de McGill",
                p2: "L'application permet aux camionneurs de programmer efficacement leur temps de chargement/déchargement en fonction de leur heure d'arrivée. Il affiche également des informations relatives aux quais de chargement, y compris les temps d'attente calculés pour chaque transporteur prévu",
                p3: "J'ai utilisé Java/Spring pour les API backend et React Native pour l'interface utilisateur mobile"
              },
              solidguard: {
                p1: "Application de sécurité Blockchain utilisée pour prendre des mesures rapides contre les vulnérabilités nouvellement découvertes dans la blockchain",
                p2: "Travail avec une équipe d'étudiants sur une série de sprints pour développer l'application (principalement axé sur le développement du frontend)",
              },
              splendor: {
                p1: "Participation à un cours de projet de groupe d'une durée d'un an qui simule le processus de développement logiciel (élicitation des exigences, mise en œuvre, intégration, tests, etc.)",
                p2: "Développement de plusieurs wireframes à l'aide de Figma pour décrire la conception de l'interface utilisateur et mise en œuvre de l'interface à l'aide de composants Web HTML + VanillaJS",
                p3: "Implémentation d'un backend Java/Spring Boot, y compris des API à interrogation longue pour transmettre les mises à jour d'état au client. Le backend intègre également un service de lobby pour fournir des fonctionnalités supplémentaires liées au lobby (authentification/autorisation via OAuth, création/rejoindre des sessions de jeu et enregistrer des données de jeu)",
                p4: "Ajout de Dockerfiles pour déployer le backend et garantie des meilleures pratiques en configurant des actions GitHub et en appliquant l'utilisation de checkstyle/tests unitaires",
              },
              mchacks: {
                t: "Mentor + Juge",
                n: "McHacks 2023 (Hackathon)",
                p1: "Assister les participants au hackathon dans la mise en œuvre de leurs projets et répondre aux questions techniques",
                p2: "Aide au jugement des présentations finales",
              },
              ottawa: {
                t: "La Galerie d’Art d’Ottawa",
                n: "Festival International d'Animation d'Ottawa",
                p1: "Aide à l'installation du lieu, vérification des laissez-passer et enregistrement de la participation aux projections",
                p2: "Aide au service des boissons et validation des pièces d'identité",
              },
              techfair: "Aide au service de vestiaire, accueil des employeurs et préparation du lieu",
              ferrari: {
                t: "Festival de la Journée Ferrari de la FCA",
                p1: "Aide à la gestion du Festival Ferrari annuel d'Ottawa avec une équipe d'étudiants",
              },
              philippines: {
                t: "Vidéaste - Célébration de la Fête de l'Indépendance des Philippines",
                h: "Parc Vincent Massey",
                p1: "Filmé le spectacle de divertissement de la fête de l'indépendance des Philippines",
                p2: "Synchronisé les séquences enregistrées avec les enregistrements audio et passé un total de 20 heures à éditer le produit final",
              },
            },
            photography: {
              t: "GALERIE DE PHOTOS",
              h1: "J'utilise le Canon EOS Rebel T6i depuis plus de 5 ans pour prendre mes photos - la plupart d'entre elles ont été prises à Ottawa et à Montréal.",
              h2: "Cliquez sur une image pour la voir en taille réelle ou la télécharger.",
              h3: "Visitez mon ",
              h4: " pour voir plus de photos",
            },
        }
      }
    }
  });

export default i18n;