import React, { Component, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import { MenuItems } from './MenuItems';
import './Navbar.css'
import colours from "../../style/colours";

export default function Navbar(props) {

    const [clicked, setClicked] = React.useState(false);
    const [isDesktop, setIsDesktop] = React.useState(false);
    const [isMobile, setIsMobile] = React.useState(false);

    const { t, i18n } = useTranslation();

    const VKLogo = () => {
        return (
            <Link
                to="/"
                activeStyle={{ color: "red" }}
                target="blank"
            >
                <img className="navbar-logo" src={require("../../images/VK.png")} alt={"VK"} />
           </Link>
        );
    }

    useEffect(() => {
        i18n.changeLanguage(props.selectedLanguage);
      }, [props.selectedLanguage]);

    React.useEffect(() => {
        if (window.innerWidth > 769) {
            setIsDesktop(true);
            setIsMobile(false);
        } else {
            setIsMobile(true);
            setIsDesktop(false);
        }
    }, []);

    return (
        <nav 
            className="NavbarItems" 
            style={{ 
                background: 'rgba(0, 0, 0, 0.3)', 
                position: 'absolute', 
                top: 0,
                width: "100%", 
                boxShadow: "0 0 1rem 0 rgba(0, 0, 0, .9)" ,
                zIndex: 5000,
            }}
        >
            {isDesktop ? <VKLogo /> : <h3></h3>}

            <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
                {MenuItems.map((item, index) => {
                    return (
                        <li key={index}><a className={item.cName} href={item.url}>{t(`button.${item.title}`)}</a></li>
                    )
                })}

            </ul>
        </nav>
    )

}